const genres = [
  { title: "Select Genres" },
  { title: "Adventure" },
  { title: "Animation" },
  { title: "Biography" },
  { title: "Comedy" },
  { title: "Crime" },
  { title: "Documentary" },
  { title: "Drama" },
  { title: "Family" },
  { title: "Fantasy" },
  { title: "Fiction" },
  { title: "Film-Noir" },
  { title: "Game Show" },
  { title: "History" },
  { title: "Horror" },
  { title: "Music" },
  { title: "Musical" },
  { title: "Mystery" },
  { title: "News" },
  { title: "Reality TV" },
  { title: "Romance" },
  { title: "Sci-Fi" },
  { title: "Superhero" },
  { title: "Sport" },
  { title: "Talk Show" },
  { title: "Thriller" },
  { title: "War" },
  { title: "Western" },
];

export default genres;
